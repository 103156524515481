import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security, SecureRoute } from '@okta/okta-react';
import Home from './Home';
import Profile from './Profile';

const oktaAuth = new OktaAuth({
  issuer: 'https://dev-10193334.okta.com/oauth2/default',
  clientId: '0oajbovjwot69GoKW5d7',
  redirectUri: '/',
  // redirectUri: window.location.origin + '/login/callback',
  scopes: ['openid', 'offline_access']
});
console.log("oktaAuth", oktaAuth);


const App = () => {
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    window.location.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login/callback" element={<LoginCallback />} />
        <Route path="/profile" component={<Profile />} />
      </Routes>
    </Security>
  );
};

const RouterApp = () => (
  <Router>
    <App />
  </Router>
);

export default RouterApp;
