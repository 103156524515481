import React from 'react';
import { withOktaAuth } from '@okta/okta-react';
import './App.css';
import logo from './logo.svg';

const Home = ({ oktaAuth, authState }) => {
    const login = async () => {
      const response =  await oktaAuth.signInWithRedirect();
      console.log("response", response);
      
    };

    const logout = async () => {
        await oktaAuth.signOut();
    };

    return (
        <div className="App">
            {/* <header className="App-header"> */}
                {/* <img src={logo} className="App-logo" alt="logo" />
                <p>
                    Edit <code>src/Home.js</code> and save to reload.
                </p>
                <a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
                    Learn React
                </a> */}
                {authState?.isAuthenticated ? (
                    <div className="Buttons">
                        <button onClick={logout}>Logout</button>
                        {/* Replace me with your root component. */}
                    </div>
                ) : (
                    <div className="Buttons">
                        <button onClick={login}>Login</button>
                    </div>
                )}
            {/* </header> */}
        </div>
    );
};

export default withOktaAuth(Home);
